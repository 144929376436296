<template>
  <div class="FolderLogin">
    <span class="FolderLogin-label">Password:</span>
    <form @submit.prevent="checkLogin">
      <input type="text" v-model="password" :class="{'-error': error}" @focus="error = false" />
    </form>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "FolderLogin",
  data() {
    return {
      error: false,
      password: "",
    };
  },
  computed: {
    ...mapGetters(["apiUrl"]),
  },
  methods: {
    checkLogin() {
      const { folder } = this.$route.params;

      const formData = new FormData();
      formData.append("input", this.password);
      formData.append("folder", folder);

      axios.post(`${this.apiUrl}/check-access`, formData).then((res) => {
        if (res.data) {
          this.$store.commit("SET_LOGIN_STATE", true);
          this.$router.push({
            name: "Folder",
            params: { folder },
          });
        } else {
          this.error = true;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.FolderLogin {
  text-align: left;
  max-width: 768px;
  margin: auto;

  input {
    border-radius: 4px;
    padding: 10px 15px;
    appearance: none;
    outline: none;
    border: 1px solid transparent;
    box-shadow: inset 0 0.0625em 0.125em rgba(0, 0, 0, 0.25);

    &:focus {
      border-color: #7957d5;
      box-shadow: 0 0 0 0.125em rgba(121, 87, 213, 0.25);
    }

    &.-error {
      border-color: #f14668;
      box-shadow: none;
    }
  }

  &-label {
    display: block;
    font-size: 12px;
    margin-bottom: 5px;
  }
}
</style>