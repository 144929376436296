<template>
  <div class="FilesList">
    <File v-for="(file, i) in files" ref="files" :key="i" :file="file" />
  </div>
</template>

<script>
import File from "@/components/File.vue";
import axios from "axios";
import mediumZoom from 'medium-zoom'
import { mapState, mapGetters } from "vuex";

export default {
  name: "FilesList",
  components: { File },
  data() {
    return {};
  },
  computed: {
    ...mapState(["files"]),
    ...mapGetters(["apiUrl"]),
  },
  watch: {
    "$route.params.folder": {
      handler() {
        this.loadFolderContents();
      },
    },
  },
  mounted() {
    this.loadFolderContents();
  },
  methods: {
    loadFolderContents() {
      const formData = new FormData();
      formData.append("folder", this.$route.params.folder);

      axios.post(`${this.apiUrl}/get-files`, formData).then((res) => {
        this.$store.commit("ADD_FILES", res.data);

        window.requestAnimationFrame(this.initImageZooms);
      });

      this.$store.commit("SET_LOGIN_STATE", false);
    },
    initImageZooms() {
      const images = [];

      this.$refs.files.forEach(file => {
          if (file.isImage) images.push(file.$refs.imgEl);
      });

      if (images.length) mediumZoom(images);
    }
  },
};
</script>

<style lang="scss" scoped>
.FilesList {
  display: flex;
  flex-wrap: wrap;
  margin: -15px -10px;
}
</style>
