import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loggedIn: false,
    files: [],
  },
  getters: {
    apiUrl() {
      if (process.env.NODE_ENV !== "development") {
        return process.env.VUE_APP_API_URL_LIVE;
      }

      return "http://localhost:3000";
    },
  },
  mutations: {
    SET_LOGIN_STATE(state, payload) {
      state.loggedIn = payload;
    },
    ADD_FILES(state, payload) {
      state.files = payload;
    },
    ADD_FILE(state, payload) {
      state.files = [...state.files, payload];
    },
  },
  actions: {},
  modules: {},
});
