import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import Home from "../views/Home.vue";
import Folder from "../views/Folder.vue";
import FolderLogin from "../views/FolderLogin.vue";

Vue.use(VueRouter);

import store from "../store";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/:folder",
    name: "Folder",
    component: Folder,
    meta: { auth: true },
  },
  {
    path: "/:folder/login",
    name: "FolderLogin",
    component: FolderLogin,
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (!store.state.loggedIn && to.meta.auth != null && to.meta.auth === true) {
    // if is sensible route
    const formData = new FormData();
    formData.append("folder", to.params.folder);

    axios.post(`${store.getters.apiUrl}/has-access`, formData).then((res) => {
      if (res.data) {
        next({ name: "FolderLogin", params: { folder: to.params.folder } });
      } else {
        next();
      }
    });
  } else {
    // else
    next();
  }
});

export default router;
