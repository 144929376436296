<template>
  <div class="Folder container" @dragover="onDragOverParent">
    <div
      v-if="isDragOver"
      @dragover="onDragOver"
      @drop="onDrop"
      @dragleave="onDragLeave"
      class="Folder-drag-box"
    />

    <div v-if="isUploading" class="Folder-loading">
      <div
        :style="{ width: (uploadProgress / uploadTotal) * 100 + '%' }"
        class="Folder-loading-bar"
      ></div>
    </div>

    <h1>/{{ $route.params.folder }}</h1>
    <FilesList />
  </div>
</template>

<script>
import FilesList from "@/components/FilesList.vue";

import axios from "axios";
import { mapGetters } from "vuex";

export default {
  name: "Folder",
  components: { FilesList },
  data() {
    return {
      isDragOver: false,
      isUploading: false,
      uploadProgress: 0,
      uploadTotal: 0,
    };
  },
  computed: {
    ...mapGetters(["apiUrl"]),
  },
  methods: {
    onDragOverParent(e) {
      e.preventDefault();

      this.isDragOver = true;
    },
    onDragOver(e) {
      e.preventDefault();
    },
    onDrop(e) {
      e.preventDefault();

      const { items } = e.dataTransfer;

      if (items.length) {
        const formData = new FormData();
        let itemLength = 0;

        for (let i = 0; i < items.length; i++) {
          if (items[i].kind === "file") {
            const file = items[i].getAsFile();

            itemLength++;
            formData.append("files", file);
          }
        }

        if (itemLength === 0) return;

        formData.append("folder", this.$route.params.folder);

        this.isUploading = true;

        const config = {
          onUploadProgress: (e) => {
            const { loaded, total } = e;
            this.uploadProgress = loaded;
            this.uploadTotal = total;
          },
        };

        axios.post(`${this.apiUrl}/upload`, formData, config).then((res) => {
          this.isUploading = false;
          this.uploadProgress = 0;
          this.uploadTotal = 0;

          let alertString = "";

          if (res.data.success.length) {
            alertString = alertString + "Erfolgreich hochgeladen:\n";
            for (let i = 0; i < res.data.success.length; i++) {
              alertString = alertString + res.data.success[i].file + "\n";

              this.$store.commit("ADD_FILE", res.data.success[i]);
            }
          }

          if (res.data.success.length && res.data.error.length)
            alertString = alertString + "\n";

          if (res.data.error.length) {
            alertString = alertString + "Gibt es schon:\n";
            for (let i = 0; i < res.data.error.length; i++) {
              alertString = alertString + res.data.error[i] + "\n";
            }
          }

          alert(alertString);
        });

        this.isDragOver = false;
      }
    },
    onDragLeave() {
      this.isDragOver = false;
    },
  },
};
</script>

<style lang="scss">
.Folder {
  flex-grow: 1;
  padding: 10px 10px 50px;

  h1 {
    color: white;
    -webkit-text-stroke: 1px #d0d0d0;
    font-style: italic;
    padding: 10px 35px;
    background: #4b5492;
    display: inline-block;
    border-radius: 5px;
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.1);
  }

  &-drag-box {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: red;
    opacity: 0.5;
  }

  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-loading-bar {
    height: 30px;
    background: green;
  }
}
</style>
