<template>
  <div class="File">
    <button @click="downloadFile" class="File-download-btn">
        <font-awesome-icon :icon="icons.download" size="lg" />
    </button>
    <div class="File-preview-wrp">
      <img
        v-if="isImage"
        ref="imgEl"
        :src="imagePath"
        class="File-image"
        draggable="false"
      />
      <div v-else class="File-placeholder">
        <font-awesome-icon :icon="mimeIcon" />
      </div>
    </div>
    <div class="File-info-wrp">
      <span>{{ file.file }}</span>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import FileSaver from "file-saver";
import { faDownload, faFileAlt, faFileCode, faFileArchive, faFile } from '@fortawesome/free-solid-svg-icons'
import { mapGetters } from "vuex";

const iconMap = {
  'application/rtf': 'text',
  'text/html': 'code',
  'application/zip': 'archive'
}

export default {
  props: ["file"],
  computed: {
    ...mapGetters(["apiUrl"]),
    isImage() {
      return this.file.type.mime.includes("image");
    },
    imagePath() {
      if (!this.isImage) return null;
      return "data:" + this.file.type.mime + ";base64," + this.file.data;
    },
    iconMap() { return iconMap },
    mimeIcon() {
      return this.icons[this.iconMap[this.file.type.mime]] || this.icons.default;
    },
    icons() {
      return {
        default: faFile,
        download: faDownload,
        text: faFileAlt,
        code: faFileCode,
        archive: faFileArchive
      }
    }
  },
  methods: {
    downloadFile() {
      const formData = new FormData();
      formData.append("file", this.file.file);
      formData.append("folder", this.$route.params.folder);
      formData.append("type", this.file.type.mime);

      axios
        .post(`${this.apiUrl}/download`, formData, {
          responseType: "blob",
        })
        .then((res) => {
          const blob = new Blob([res.data], { type: this.file.type.mime });
          FileSaver.saveAs(blob, this.file.file);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
$light-grey: #f3f3ee;

.medium-zoom-image--opened { z-index: 101; object-fit: contain !important; }

.File {
  position: relative;
  width: calc(100% / 3 - 20px);
  margin: 15px 10px;
  border-radius: 6px;

  @media (min-width: 767px) {
    width: calc(100% / 4 - 20px);
  }

  @media (min-width: 991px) {
    width: calc(100% / 6 - 20px);
  }

  &:hover {
    background: $light-grey;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &-download-btn {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    top: 10px;
    right: 10px;
    padding: 5px 8px;
    outline: 2px solid #4b5492;
    border: none;
    color: #4b5492;
    pointer-events: none;
    opacity: 0;
    transform: translate3d(0, -50%, 0);
    transition: transform 0.3s ease, opacity 0.3s;

    .File:hover & {
      pointer-events: auto;
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &-preview-wrp {
    display: flex;
    flex-direction: column;
    padding: 10px;
    position: relative;

    &::before {
      content: "";
      width: 100%;
      padding-top: 100%;
    }
  }

  &-placeholder,
  &-image {
    width: calc(100% - 10px * 2);
    height: calc(100% - 10px * 2);
    border-radius: 3px;
    object-fit: cover;
    position: absolute;
  }

  &-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 2px solid black;

    svg {
      width: 35%;
      height: 35%;

      * {
        fill: black;
      }
    }
  }

  &-info-wrp {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 3;

    .File:hover & {
      background: $light-grey;
      border-bottom-right-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    span {
      padding: 0 10px 10px;
      font-size: 0.6em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
      font-weight: bold;

      @media (min-width: 580px) {
        padding: 0 20px 10px;
        font-size: 0.8em;
      }

      .File:hover & {
        white-space: unset;
        color: #2c3e50;
      }
    }
  }
}
</style>
